import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { PropTypes, func } from 'prop-types';
import Header from 'components/Header/Header';

const NotFound = ({
  intl, lang, handleLang, handleStyle,
}) => {
  const langInit = sessionStorage.getItem('lang') ? sessionStorage.getItem('lang') : 'fr';
  const styleInit = sessionStorage.getItem('style') ? sessionStorage.getItem('style') : 'default';
  useEffect(() => {
    handleLang(langInit);
    handleStyle(styleInit);
  }, [langInit, styleInit]);

  return (
    <>
      <Header lang={lang} handleLang={handleLang} handleStyle={handleStyle} />
      <main>
        <section className="container">
          <div className="unavailable-site">
            <h1>
              {
                intl.formatMessage({ id: 'page_non_existant' })
              }
            </h1>
          </div>
        </section>
      </main>
    </>
  );
};

NotFound.propTypes = {
  intl: PropTypes.shape({ formatMessage: func }).isRequired,
  lang: PropTypes.string.isRequired,
  handleLang: PropTypes.func.isRequired,
  handleStyle: PropTypes.func.isRequired,
};

NotFound.defaultProps = {};

export default injectIntl(NotFound);
