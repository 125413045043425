import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import Header from 'components/Header/Header';
import { getProperty } from 'utils/config';

const Version = ({
  lang, handleLang, handleStyle,
}) => {
  const v = getProperty('version');
  const langInit = sessionStorage.getItem('lang') ? sessionStorage.getItem('lang') : 'fr';
  const styleInit = sessionStorage.getItem('style') ? sessionStorage.getItem('style') : 'default';
  useEffect(() => {
    handleLang(langInit);
    handleStyle(styleInit);
  }, [langInit, styleInit]);

  return (
    <>
      <Header lang={lang} handleLang={handleLang} handleStyle={handleStyle} />
      <main>
        <section className="container">
          <div className="unavailable-page">
            <h1>Version</h1>
            {v}
          </div>
        </section>
      </main>
    </>
  );
};

Version.propTypes = {
  lang: PropTypes.string.isRequired,
  handleLang: PropTypes.func.isRequired,
  handleStyle: PropTypes.func.isRequired,
};

Version.defaultProps = {};

export default Version;
